import React, { useState } from "react";
import * as S from "./FeedbackModal.styled";

interface FeedbackModalProps {
  onClose: () => void;
  onSubmit: (feedback: string) => void;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (feedback.trim() === "") {
      setError("Feedback cannot be empty.");
      return;
    }
    onSubmit(feedback);
    onClose();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(e.target.value);
    if (e.target.value.trim() !== "") {
      setError(""); 
    }
  };

  return (
    <S.ModalWrapper>
      <S.ModalContent>
        <S.StyledTextarea
          value={feedback}
          onChange={handleTextareaChange}
          rows={1}
          placeholder="Enter your feedback"
          required
        />
        <S.ButtonRow>
          <S.StyledButton onClick={handleSubmit}>Submit</S.StyledButton>
          <S.StyledButton onClick={onClose}>Cancel</S.StyledButton>
        </S.ButtonRow>
        {error && <S.ErrorMessage>{error}</S.ErrorMessage>}

      </S.ModalContent>
    </S.ModalWrapper>
  );
};
