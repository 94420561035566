import React from "react";

interface LikeIconProps {
  isDisabled: boolean;
  onClick: () => void;
}

export const LikeIcon = ({ isDisabled, onClick }: LikeIconProps) => {
  return (
    <svg
      fill={isDisabled ? "#fee011" : "none"}
      stroke={isDisabled ? "none" : "#fee011"}
      strokeWidth={isDisabled ? "0" : "10"}
      height="18px"
      width="18px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232.666 232.666"
      onClick={onClick}
      style={{ cursor: "pointer", transform: "scaleY(-1)" }}
    >
      <g>
        <polygon
          style={{ fill: isDisabled ? "#fee011" : "none" }}
          points="135.075,6 135.075,6 135.075,12"
        />
        <path
          style={{
            fill: isDisabled ? "#fee011" : "none",
            stroke: isDisabled ? "none" : "#fee011",
            strokeWidth: isDisabled ? "0" : "10",
          }}
          d="M66.706,36.957c-0.544,0.357-1.162,0.598-1.806,0.696L36.03,42.057
            c-2.228,0.341-3.956,2.257-3.956,4.511v79.825c0,1.204,33.353,20.624,43.171,30.142c12.427,12.053,21.31,34.681,33.983,54.373
            c4.405,6.845,10.201,9.759,15.584,9.759c10.102,0,18.831-10.273,14.493-24.104c-4.018-12.804-8.195-24.237-13.934-34.529
            c-4.672-8.376,1.399-18.7,10.989-18.7h48.991c18.852,0,18.321-26.312,8.552-34.01c-1.676-1.32-2.182-3.682-1.175-5.562
            c3.519-6.572,2.86-20.571-6.054-25.363c-2.15-1.156-3.165-3.74-2.108-5.941c3.784-7.878,3.233-24.126-8.71-27.307
            c-2.242-0.598-3.699-2.703-3.405-5.006c0.909-7.13-0.509-20.86-22.856-26.447C145.103,12.573,140.272,12,135.127,12
            C116.039,12.001,92.674,19.903,66.706,36.957z"
        />
        <path
          d="M205.428,104.032c2.808-10.145,1.126-24.127-7.888-32.497c1.739-7.042,1.702-15.878-1.899-23.703
            c-2.427-5.272-6.24-9.397-11.017-12.013c-0.166-4.677-1.302-10.208-4.609-15.684c-5.291-8.757-14.575-14.84-27.538-18.08
            C147.025,0.691,141.075,0,135.075,0v6v6V6V0.333h0.053c-21.872,0-47.307,8.831-73.643,25.869l-27.269,4.076
            c-8.145,1.245-14.141,8.047-14.141,16.289v79.825c0,6.707,4.81,9.88,8.609,12.43c2.111,1.417,5.014,3.256,8.325,5.385
            c9.237,5.936,24.717,15.874,29.947,20.943c6.534,6.338,12.574,17.456,18.955,29.227c4.106,7.572,8.358,15.402,13.263,23.024
            c6.243,9.701,15.596,15.265,25.656,15.265c8.583,0,16.793-4.2,21.958-11.233c5.746-7.825,7.196-18.199,3.976-28.463
            c-3.56-11.343-8.114-24.58-14.909-36.764c-0.079-0.142-0.154-0.296,0.008-0.57c0.166-0.285,0.334-0.303,0.496-0.303h48.991
            c11.977,0,21.461-6.717,25.371-17.998C214.609,126.123,212.239,112.62,205.428,104.032z M149.594,13.697
            c22.348,5.586,23.766,19.317,22.856,26.446c-0.294,2.303,1.163,4.409,3.405,5.007c11.943,3.181,12.494,19.43,8.71,27.308
            c-1.057,2.201-0.042,4.788,2.108,5.943c8.914,4.793,9.572,18.796,6.054,25.368c-1.007,1.882-0.501,4.233,1.175,5.554
            c9.77,7.697,10.3,34.01-8.552,34.01H136.36c-9.59,0-15.655,10.324-10.984,18.7c5.74,10.292,9.922,21.717,13.939,34.52
            c4.34,13.83-4.379,24.107-14.482,24.107c-5.383,0-11.156-2.911-15.562-9.756c-12.673-19.692-21.597-42.318-34.024-54.373
            c-9.818-9.517-43.171-28.935-43.171-30.14V46.567c0-2.254,1.728-4.17,3.956-4.511l28.828-4.403
            c0.644-0.099,1.283-0.339,1.827-0.696C92.653,19.903,116.028,12.001,135.117,12C140.261,12,145.103,12.574,149.594,13.697z"
        />
      </g>
    </svg>
  );
};
