import React, { useState } from "react";
import * as S from "./Message.styled";
import Markdown from "react-markdown";
import { Annotation } from "../../types";
import { DislikeIcon } from "../DislikeIcon";
import { LikeIcon } from "../LikeIcon";
import { useAssistantChat } from "../../context";
import { FeedbackModal } from "../FeedbackModal";

interface MessageProps {
  content?: string;
  position: "left" | "right";
  id: string;
  withoutMarginBottom?: boolean;
  annotations?: Annotation[];
  messageId?: string;
  isDisliked?: boolean;
  isLiked?: boolean;
}

const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_PROD
    : process.env.REACT_APP_BACKEND_URL_LOCAL;

export const Message: React.FC<MessageProps> = ({
  content,
  position,
  id,
  withoutMarginBottom,
  annotations,
  messageId,
  isDisliked,
  isLiked,
}) => {
  const { handleDislike, handleLike } = useAssistantChat();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDislikeClick = () => {
    if (messageId && !isDisliked) {
      setIsModalOpen(true);
    }
  };

  const handleLikeClick = async () => {
    if (messageId && !isLiked) {
      await handleLike(messageId);
    }
  };

  const handleFeedbackSubmit = async (feedback: string) => {
    if (messageId) {
      await handleDislike(messageId, feedback);
    }
  };

  const fileNameSet = new Set<string>();

  return (
    <S.Wrapper
      position={position}
      id={id}
      withoutMarginBottom={withoutMarginBottom}
    >
      <S.ContentWrapper position={position}>
        <Markdown>{content}</Markdown>
        {annotations && annotations.length > 0 && (
          <S.Source>
            Sources:&nbsp;
            {annotations
              .filter((annotation) => {
                const filename = annotation.filename;
                const isDuplicate = fileNameSet.has(filename);
                fileNameSet.add(filename);
                return !isDuplicate;
              })
              .map((annotation: Annotation) => (
                <a
                  key={annotation.file_citation.file_id}
                  href={`${BACKEND_URL}/download/${annotation.filename}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${annotation.filename}`}
                </a>
              ))}
          </S.Source>
        )}
      </S.ContentWrapper>
      {position === "left" && (
        <S.FeedbackControls>
          {!isDisliked && (
            <S.LikeWrapper>
              <LikeIcon isDisabled={isLiked!} onClick={handleLikeClick} />
              {!isLiked && <S.Tooltip>I like this answer</S.Tooltip>}
            </S.LikeWrapper>
          )}
          {!isLiked && (
            <S.DislikeWrapper>
              <DislikeIcon
                isDisabled={isDisliked!}
                onClick={handleDislikeClick}
              />
              {!isModalOpen && !isDisliked && (
                <S.Tooltip>I don't like this answer</S.Tooltip>
              )}
              {isModalOpen && (
                <FeedbackModal
                  onClose={() => setIsModalOpen(false)}
                  onSubmit={handleFeedbackSubmit}
                />
              )}
            </S.DislikeWrapper>
          )}
        </S.FeedbackControls>
      )}
    </S.Wrapper>
  );
};
