import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: absolute;
  left: 30px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
`;

export const ModalContent = styled.div`
  background: #0d88d3;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  color: white;
`;

export const StyledTextarea = styled.textarea`
  font-family: "Arial", sans-serif;
  background: white;
  border: 1px solid #0d88d3;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  outline: none;
  width: 300px;

  &::placeholder {
    font-size: 14px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

export const StyledButton = styled.button`
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  color: white;
  font-weight: bold;
  padding: 5px;

  &:hover {
    background-color: white;
    color: #0d88d3;
  }
`;

export const ErrorMessage = styled.div`
  color: #fee011;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;
