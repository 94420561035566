import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { APIResponseT, AssistantMessage, Attachment, MessageT } from "../types";
import axios from "axios";
import ReconnectingWebSocket from "reconnecting-websocket";

const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_PROD
    : process.env.REACT_APP_BACKEND_URL_LOCAL;

export type ToggleType = "marketing" | "information";

type AssistantChatContextT = {
  messages: MessageT[];
  thread: string;
  setMessages: React.Dispatch<React.SetStateAction<MessageT[]>>;
  sendMessage: (message: string) => Promise<void>;
  connectToThreadStatus: () => void;
  disconnectFromThreadStatus: () => void;
  createNewConversation: () => Promise<void>;
  clearThread: () => Promise<void>;
  isWaitingForResponse: boolean;
  setIsWaitingForResponse: React.Dispatch<React.SetStateAction<boolean>>;
  isSendingMessage: boolean;
  setIsSendingMessage: React.Dispatch<React.SetStateAction<boolean>>;
  assistantType: ToggleType;
  onAssistantTypeChange: (type: ToggleType) => Promise<void>;
  handleDislike: (messageId: string, feedback: string) => Promise<void>;
  handleLike: (messageId: string) => Promise<void>;
};

export const AssistantChatContext = createContext<AssistantChatContextT>({
  messages: [],
  thread: "",
  setMessages: () => {},
  sendMessage: () => Promise.resolve(),
  connectToThreadStatus: () => {},
  disconnectFromThreadStatus: () => {},
  createNewConversation: () => Promise.resolve(),
  clearThread: () => Promise.resolve(),
  isWaitingForResponse: false,
  setIsWaitingForResponse: () => {},
  isSendingMessage: false,
  setIsSendingMessage: () => {},
  assistantType: "information",
  onAssistantTypeChange: () => Promise.resolve(),
  handleDislike: () => Promise.resolve(),
  handleLike: () => Promise.resolve(),
});

type MessagesProviderProps = {
  children: React.ReactNode;
};

export const AssistantChatProvider: React.FC<MessagesProviderProps> = ({
  children,
}) => {
  const abortControllerRef = useRef(new AbortController());
  const [thread, setThread] = useState<string>(
    localStorage.getItem("thread") ?? ""
  );
  const [messages, setMessages] = useState<MessageT[]>(
    JSON.parse(localStorage.getItem("messages") ?? "[]")
  );
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [assistantType, setAssistantType] = useState<ToggleType>(
    (localStorage.getItem("assistantType") as ToggleType) || "information"
  );
  const ws = useRef<ReconnectingWebSocket | null>(null);

  useEffect(() => {
    // Store messages in local storage
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    // Store thread in local storage
    if (thread && localStorage.getItem("thread") !== thread) {
      console.log(`Thread changed: ${thread}`);
      localStorage.setItem("thread", thread);
    }
  }, [thread]);

  useEffect(() => {
    // Store assistantType in local storage
    localStorage.setItem("assistantType", assistantType);
  }, [assistantType]);

  useEffect(() => {
    // Listen to the ws endpoint thread-status
    if (!thread || !isWaitingForResponse) return;

    connectToThreadStatus();

    return () => {
      ws?.current?.close();
    };
  }, [isWaitingForResponse, thread]);

  useEffect(() => {
    const localMessages = localStorage.getItem("messages");
    let parsedMessages: MessageT[] = [];
    if (localMessages) {
      parsedMessages = JSON.parse(localMessages);
    }

    if (parsedMessages.length > 0) {
      console.log(`Messages found: ${parsedMessages.length}`);
      setMessages(parsedMessages);
    }

    const localThread = localStorage.getItem("thread");
    if (localThread) {
      console.log(`Thread found: ${localThread}`);
      setThread(localThread);
    }
  }, []);

  const processAssistantMessages = (
    assistantResponse: AssistantMessage[]
  ): MessageT[] => {
    return assistantResponse?.map((message) => {
      const attachments: Attachment[] = [];
      const annotations = message.content[0].annotations;
      let msg = message.content[0].value;

      const sourcePattern = /【\d+:\d+†[^】]+】/g;
      msg = msg.replace(sourcePattern, "");

      const isDisliked = messages.find(
        (m) => m.messageId === message.id
      )?.isDisliked;

      const isLiked = messages.find((m) => m.messageId === message.id)?.isLiked;

      return {
        content: msg,
        position: message.role === "assistant" ? "left" : "right",
        attachments: attachments,
        annotations: annotations,
        messageId: message.id,
        isDisliked: isDisliked,
        isLiked: isLiked,
      } as MessageT;
    });
  };

  const disconnectFromThreadStatus = () => {
    if (ws.current) {
      ws.current.close();
    }
  };

  const connectToThreadStatus = useCallback(() => {
    const wsUrl = new URL(
      `${BACKEND_URL}/thread-status?threadId=${thread}&assistantType=${assistantType}`
    );
    if (wsUrl.protocol === "https:") {
      wsUrl.protocol = "wss:";
    } else {
      wsUrl.protocol = "ws:";
    }
    ws.current = new ReconnectingWebSocket(`${wsUrl}`, [], {
      maxRetries: 10,
      connectionTimeout: 3000,
    });

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(`Received message from ws: ${JSON.stringify(data)}`);
      if (data.status === "completed") {
        const messages = processAssistantMessages(data.messages);
        if (messages?.length > 0) {
          setMessages([...messages.reverse()]);
        }
        setIsWaitingForResponse(false);
      } else if (data.status === "failed" || data.status === "cancelled") {
        setMessages([
          {
            content:
              "Sorry, something went wrong. Please start a new conversation.",
            position: "left",
            isDisliked: false,
            isLiked: false,
          },
        ]);
        setIsWaitingForResponse(false);
      }
    };
  }, [thread, assistantType, messages]);

  const sendMessage = async (message: string) => {
    if (!message) {
      setIsWaitingForResponse(false);
      return;
    }
    setIsSendingMessage(true);
    setMessages([
      ...messages,
      {
        content: message,
        position: "right",
        isDisliked: false,
        isLiked: false,
      },
    ]);

    try {
      await axios.get<APIResponseT>(`${BACKEND_URL}/ask`, {
        params: { threadId: thread, message, assistantType },
        signal: abortControllerRef.current.signal,
      });
      setIsSendingMessage(false);
      setIsWaitingForResponse(true);
    } catch (err) {
      console.log(err);
      setMessages([
        ...messages,
        {
          content: `Sorry, something went wrong. Please start a new conversation. Error: ${err}`,
          position: "left",
          isDisliked: false,
          isLiked: false,
        },
      ]);
      setIsWaitingForResponse(false);
    } finally {
      setIsSendingMessage(false);
    }
  };

  const onAssistantTypeChange = async (type: ToggleType) => {
    try {
      setAssistantType(type);
      // await createNewConversation(); -> opens chat when assistant type is changed
    } catch (err) {
      console.error("Failed to change assistant:", err);
    }
  };

  const abortActiveRequests = () => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
  };

  const createThread = async (assistantType: ToggleType) => {
    const res = await axios.get(`${BACKEND_URL}/createThread`, {
      params: { assistantType },
    });
    setThread(res.data);
  };

  const createNewConversation = async () => {
    abortActiveRequests();
    await createThread(assistantType);
    setMessages([]);
    setIsWaitingForResponse(false);
  };

  const clearThread = async () => {
    setThread("");
    setMessages([]);
    localStorage.removeItem("thread");
    localStorage.removeItem("messages");
    setIsWaitingForResponse(false);
    disconnectFromThreadStatus();
    abortActiveRequests();
  };

  const handleLike = async (messageId: string) => {
    try {
      setMessages((prevMessages) => {
        const selectedMessage = prevMessages.find(
          (message) => message.messageId === messageId
        );

        axios.post(`${BACKEND_URL}/log-like`, {
          messageId,
          messageContent: selectedMessage?.content,
        });

        return prevMessages.map((message) =>
          message.messageId === messageId
            ? { ...message, isLiked: !message.isLiked }
            : message
        );
      });
    } catch (error) {
      console.error("Failed to log like action:", error);
    }
  };

  const handleDislike = async (messageId: string, feedback: string) => {
    try {
      setMessages((prevMessages) => {
        const selectedMessage = prevMessages.find(
          (message) => message.messageId === messageId
        );

        axios.post(`${BACKEND_URL}/log-dislike`, {
          messageId,
          messageContent: selectedMessage?.content,
          feedback,
        });

        return prevMessages.map((message) =>
          message.messageId === messageId
            ? {
                ...message,
                isDisliked: !message.isDisliked,
              }
            : message
        );
      });
    } catch (error) {
      console.error("Failed to log dislike action:", error);
    }
  };

  return (
    <AssistantChatContext.Provider
      value={{
        messages,
        thread,
        setMessages,
        sendMessage,
        connectToThreadStatus,
        disconnectFromThreadStatus,
        createNewConversation,
        clearThread,
        isWaitingForResponse,
        setIsWaitingForResponse,
        isSendingMessage,
        setIsSendingMessage,
        assistantType,
        onAssistantTypeChange,
        handleDislike,
        handleLike,
      }}
    >
      {children}
    </AssistantChatContext.Provider>
  );
};

export const useAssistantChat = () => React.useContext(AssistantChatContext);
